import styled from 'styled-components';

const ContentWrapper = styled.div`
  position: relative;
  /* background: red; */
  overflow: hidden;
  padding: 15px 0 40px 0;

  .user_list_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
  }

  .search_input {
    flex-grow: 1;
    margin-right: 20px;

    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          font-size: 14px;
          color: #fff;
        }
        .highlight {
          background: #fff;
          height: 1px;
        }
      }
    }

    .highlight {
      background: rgba(255, 255, 255, 0.4);
    }

    input {
      background: transparent;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      padding: 10px 15px;
      border-color: #dadada;
      @media (max-width: 575px) {
        height: 48px;
      }
    }

    label {
      font-size: 17px;
      color: #fff;
      font-weight: 400;
      padding-left: 10px;
      top: 5px;
      pointer-events: none;
    }
  }
`;

const Styles = {
  // rowCentered: {
  //   textAlign: 'center',
  // },
  infiniteScroll: {
    height: '700px',
    // height: '1300px',
    overflow: 'auto',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    // width: [1],
    alignItems: 'center',
    justifyContent: 'center',
    // mt: '50px',
    // mb: '50px',
    mt: '10px',
    mb: '10px',
  },
  // col: {
  //   // width: [1, 1 / 2, 1 / 2, 1 / 2],
  //   width: [1, 1 / 4, 1 / 4, 1 / 4],
  //   pl: ['30px', '30px', '30px', '25px', '45px'],
  //   pr: ['30px', '30px', '30px', '25px', '45px'],
  //   mb: ['50px', '70px'],
  // },
  podCol: {
    // [ xs, sm, md, lg  ]
    // width: [1, 1 / 3, 1 / 2, 1 / 3],
    width: [1],
    // pl: ['30px', '30px', '30px', '25px', '45px'],
    // pr: ['30px', '30px', '30px', '25px', '45px'],
    // mb: ['25px', '25px'],
  },
  // heading: {
  //   fontSize: ['24px', '32px', '40px', '42px', '46px'],
  //   fontWeight: '700',
  //   // color: '#000',
  //   letterSpacing: '-0.025em',
  //   mb: ['20px', '25px', '25px', '25px', '25px'],
  //   lineHeight: '1.2',
  //   textAlign: 'center',
  // },
  // bodyText: {
  //   // fontSize: ['24px', '32px', '40px', '42px', '46px'],
  //   // fontFamily: 'Raleway',
  //   fontSize: ['20px'],
  //   fontWeight: '400',
  //   // letterSpacing: '-0.025em',
  //   mb: ['20px', '25px', '25px', '25px', '25px'],
  //   lineHeight: '1.5',
  //   textAlign: 'left',
  // },
  // title: {
  //   fontSize: ['36px', '44px', '44px', '44px', '58px'],
  //   // fontSize: ['24px', '32px', '40px', '42px', '46px'],
  //   fontWeight: '700',
  //   color: '#fff',
  //   letterSpacing: '-0.025em',
  //   mb: ['20px', '25px', '25px', '25px', '25px'],
  //   lineHeight: '1.2',
  //   textAlign: 'center',
  // },

  title: {
    // fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontSize: ['28px', '32px', '36px', '42px', '48px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    // textAlign: ['center', 'center', 'center', 'left', 'left'],
    textAlign: 'center',
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: '20px',
    // fontSize: ['14px', '14px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: 'center',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    color: '#03103b',
  },
};

export { Styles };
export default ContentWrapper;
