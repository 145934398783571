import React, { Fragment, useState } from 'react';
import Box from 'reusecore/src/elements/Box';
// import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
// import Image from 'reusecore/src/elements/Image';
// import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';

// import PodcastTile from 'common/src/components/PodcastTile';
import ContentWrapper, { Styles } from './styles'; // CSS styling
import { InputHandler } from './handlers'; // Code to handle user I/O

// Fake data for testing

// NPM MODULES
// import { format } from 'timeago.js';

const Player = ({ title }) => {
  // const [users, updateUsers] = useState([]);
  const { row, podCol, buttonStyle } = Styles;
  const emptyFrag = <Fragment></Fragment>;

  return (
    <ContentWrapper>
      <Container className="user_list_container">
        {/*** TITLE BAR (optional) ***/}
        {title ? (
          <Box {...row}>
            <Heading content={title} />
          </Box>
        ) : (
          emptyFrag
        )}

        <Box {...row}>
          <Button
            {...buttonStyle}
            title="Make it go"
            onClick={async () => {
              await InputHandler.getTranscript()
              // updateUsers(await InputHandler.getUsers('beta'));
            }}
          />
        </Box>
      </Container>
    </ContentWrapper>
  );
};

export default Player;
