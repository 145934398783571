/**
 * Handler for input events for Player
 *
 */

import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
// import { redirectToPage } from '../../../../api/BrowserAPI';
import { getUrlParams } from '../../../../api/BrowserAPI';
import transcript from '../../../../test-data/0b9c85d90f1a403785e4ef480b8c456f.json'

// Gets raw AWST data from a job
const getTranscript = async () => {  
  // const { jobId, userId } = getUrlParams()
  // console.log('Getting job: ' + jobId + ' from user: '+ userId);
  // // Simplified API fetch 
  // //const doFetch = async ( apiName, apiEndpoint, params = null, query = null, body = null, cb = null, errCb = null )
  // const job = await podstacksAPI( 'jobs', 'getJob', {jobId, userId}, null, null, null, null, null );

  // TEMPORARY HACK TO DOWNLOAD TRANSCRIPT
  // const url = 'https://transcribe-tool.s3-us-west-2.amazonaws.com/podstacks/transcripts/rawAWST/0b9c85d90f1a403785e4ef480b8c456f.json'
  
  // let transcript = {};

  // fetch(url)
  //   .then(response => response.json())
  //   .then(stringifiedData => transcript = JSON.parse(stringifiedData))
  //   .catch(error => console.log(`Failed because: ${error}`));

  console.log(transcript)
};


export const InputHandler = {
  getTranscript,
  // redirectToPage,
};
